/* eslint-disable prettier/prettier */
import React from "react";
import Banner from "./Banner";
import Cards from "./Cards";


export default function Home() {

  if (localStorage.getItem("successOrders")) {     localStorage.removeItem("successOrders");}
  if (localStorage.getItem("3DSecureURL")) { localStorage.removeItem("3DSecureURL");}
  if (localStorage.getItem("card_number")) {localStorage.removeItem("card_number");}

  return (
    <>
      <Banner />
      <Cards />
    </>
  );
}
