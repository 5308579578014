// import {
// primaryColor,
// dangerColor,
// successColor,
// defaultFont,
// } from "assets/jss/material-kit-react";

const customOutlinedInputStyle = {
  root: {
    borderRadius: 5
  },
  block: {
    width: "100%"
  },
};

export default customOutlinedInputStyle;
