import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1080,
    width: "100%",
    display: "flex",
    margin: "-35px auto",
    [theme.breakpoints.down("sm")]: {
      overflowX: "hidden",
      // width: "100",
      margin: 0,
      flexDirection: "column",
    },
  },

  
  twoInput: {
    width: "100%",
    display: "flex",
    //flexWrap: "wrap",
    alignItems: "center",
    margin: "20px 0",
    textAlign: "center",
  },

  emailWithNoDomain: {
    height: "50px",
    width: "75%"
  },

  selectDomains: {
    minWidth: "200px",
    width: "30%",
    height: "40px",
    size: "5px",
    marginLeft: "5px",
    marginTop: "-5px",
    textAlign: "left"
  }
  
}));
export default useStyles;
