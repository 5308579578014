import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById('modal-root');

const Modal = (props) => {

    const [container, setContainer] = useState(null);

    useEffect(() => {
        console.info(container)
        if(container===null){
            modalRoot.innerHTML = '';
            let div = document.createElement("div");
            div.style.width="100%";
            div.style.height="100%";
            setContainer(div);
            modalRoot.appendChild(div);
            modalRoot.style.display= "flex";
        }
        return () => {
            modalRoot.style.display= "none";
        }
    }, []);

    return container && createPortal(props.children, container);
};

export default Modal;