import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { TextField } from "@material-ui/core";
import { Field } from "formik";
import Select from "react-dropdown-select";
import classNames from "classnames";
import useStyles from "./style";


const InputListDomains = ({ domains, valuesFormik, touchedFormik, errorsFormik, handleChange, handleMail }) => {
    const classes = useStyles();
    const { setFieldValue } = useFormikContext();
    const [domainOptions, setDomainOptions] = useState([]);
    const [domainSelected, setDomainSelected] = useState("");
    const [mailWithNoDomain, setMailWithNoDomain] = useState("");
    const [mail, setMail] = useState();
    useEffect(() => {
        setDomainOptions(
            domains.map((domain, index) => ({
                id: index+1,
                display: domain
            }))
        );
    }, [ domains, mail]);

    useEffect(() => {
        const cleanedMail = mailWithNoDomain.split("@")[0];
        if(cleanedMail && domainSelected){
            const fullMail = `${cleanedMail}@${domainSelected}`;
            setMail(fullMail);
            handleMail(cleanedMail, domainSelected);
        } else {
            setMail(cleanedMail);
        }
    }, [mailWithNoDomain, domainSelected, handleMail]);

    const onChangeDomain = (value) => {
        const domain = value[0].display;
        setDomainSelected(domain);
        if(mailWithNoDomain){
            const fullMail = `${mailWithNoDomain}@${domain}`;
            setMail(fullMail);
            handleMail(mailWithNoDomain, domain);
            setFieldValue("emailWithNoDomain", mailWithNoDomain); // Actualiza el correo sin dominio
            //handleMail(mailWithNoDomain, domain);
        }
    };

    function preventUpperCaseLetter(value){
        const uppercaseLetters = Array.from('ABCDEFGHIJKLMNÑOPQRSTUVWXYZ');
        return uppercaseLetters.includes(value);
    }

    const onChangeInput = (e, field) => {
        
        const { value } = e.target;
        const lastChar = value[value.length - 1];
        if(preventUpperCaseLetter(lastChar)){
           return;
        }
        handleChange(e);
        const newValue = value.includes("@") ? value.split("@")[0] : value;
        field.onChange(e.target.name)(newValue);
        setMailWithNoDomain(newValue);
        const partDomain = domainSelected ? `@${domainSelected}`: "";
        setMail(`${newValue}${partDomain}`)
    }

    const preventFullMail = (e) => {
        const data = e.clipboardData || window.clipboardData;
        const pastedData = data.getData("Text");
        if(pastedData.includes("@"))
            e.preventDefault();
    }



    return (
        <div className={classNames(classes.twoInput)}>
            <Field name={"emailWithNoDomain"} id="emailWithNoDomain" value={valuesFormik.emailWithNoDomain}>
                {({ field }) => (
                    <TextField
                        inputProps={{ style: { textTransform: 'none' } }}
                        className={classNames(classes.emailWithNoDomain)}
                        variant="outlined"
                        margin="dense"
                        type="text"
                        placeholder="Email. *"
                        {...field}
                        error={touchedFormik.emailWithNoDomain && Boolean(errorsFormik.emailWithNoDomain)}
                        helperText={touchedFormik.emailWithNoDomain &&  errorsFormik.emailWithNoDomain}
                        onChange={(e) => {onChangeInput(e, field)}}
                        onPaste={preventFullMail}                       
                        onKeyDown={(e) => {
                            // Prevenir el ingreso de "@" o espacio                                    
                            if (e.key === "@" || e.key === " ") {
                                e.preventDefault();
                            }
                        }}
                    />
                )}
            </Field>
            <div>
                <Field name={"authorizedDomains"} id="authorizedDomains">
                    {({ field }) => (
                        <Select
                            {...field}
                            error={touchedFormik.authorizedDomains && Boolean(errorsFormik.authorizedDomains)}
                            value={valuesFormik.authorizedDomains}
                            className={classNames(classes.selectDomains)}
                            options={domainOptions}
                            labelField="display"
                            valueField="id"
                            autosize={true}
                            searchable={false}
                            clearable={false}
                            disabled={mailWithNoDomain.length === 0}
                            onChange={(selected) => onChangeDomain(selected)}
                        />
                    )}
                </Field>
                          
            </div>
        </div>
    )
}
export default InputListDomains;