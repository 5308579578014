import React, { useEffect } from 'react';

const Callback = () => {

  useEffect(() => {
    localStorage.setItem('secure3D', 'true');
  }, []);

  return (
    <>

    </>
  );
};

export default Callback;
