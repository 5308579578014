import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Iframe";
import { useDispatch } from "react-redux";
import { onClose3DSecure } from "redux/actions/home";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import "./style.scss";

const override = css`
  display: block;
  margin: 100px auto;
`;

const Secure3DModal = ({ open, onClose, url }) => {
  const dispatch = useDispatch();
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [openLocal, setOpenLocal] = useState(false);

  console.log("params ---> ", open, onClose, url);

  function callHandleCallback(ev) {
    if (ev.data === "3DS-authentication-complete") {
      console.log("Entra al listener");
      onClose();
    }
  }

  useEffect(() => {
    if (!sessionStorage.getItem("isListenerAttached")) {
      console.log(
        "Crea listener dentro del modal. -" +
          sessionStorage.getItem("isListenerAttached")
      );
      window.addEventListener("message", (e) => callHandleCallback(e));
      sessionStorage.setItem("isListenerAttached", true);
      return;
    }

    return () => {
      console.log("Borra listener");
      sessionStorage.removeItem("isListenerAttached");
      document.removeEventListener("message", (e) => callHandleCallback(e));
    };
  }, []);

  useEffect(() => {
    console.log("useEffect open --->", open);
    if (open) {
      setOpenLocal(true);
      setLoadingLocal(true);
      localStorage.setItem("secure3D", "false");

      console.log("dispatch onClose3DSecure --->", false);
      dispatch(onClose3DSecure(true));

      let timer = 2000;
      let interval = setInterval(() => {
        console.log(
          "localStorage getItem secure3D --->",
          localStorage.getItem("secure3D")
        );

        if (localStorage.getItem("secure3D") === "true") {
          clearInterval(interval);
          onClose(() => {
            setLoadingLocal(false);
            setOpenLocal(false);
          });
        }
      }, timer);
    }
  }, [open]);

  return (
    <>
      {open
        ? openLocal && (
            <Modal url={url.toString()}>
              <div className="modal">
                <iframe src={url.toString()} allowFullScreen />
              </div>
            </Modal>
          )
        : null}
      {loadingLocal && (
        <div className="loaderMain">
          <PulseLoader
            color={"#ab71ff"}
            speedMultiplier={1}
            loading={loadingLocal}
            css={override}
            size={15}
            margin={10}
          />
        </div>
      )}
    </>
  );
};

Secure3DModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string,
};

export default Secure3DModal;
